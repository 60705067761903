import { createContext, useContext } from 'react';
import { DynatraceStoragePlugin } from 'apps/acp/packages/acp-dynatrace';

export type DynatraceConfig = DynatraceStoragePlugin;

export const DynatracePluginContext = createContext<DynatraceStoragePlugin>(
  null as any
);

export const DynatraceConfigProvider = DynatracePluginContext.Provider;

export const useDynatraceConfig = () => useContext(DynatracePluginContext);

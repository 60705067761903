declare global {
  interface Window {
    dtrum?: any;
  }
}

interface DynatraceApiPlugin {
  /**
   * The current visit/session will be tagged with the provided identity.
   * The user assigned to a session can be identified. This enables you
   * to search and filter specific user sessions and
   * analyze individual user behavior over time in the backend.
   */
  identifyUser: (identity: string) => void;

  /**
   * This function can be used within your application to end user sessions
   * automatically, for example, when the user logs out. You can also use
   * this API call when you want to immediately end browser sessions for
   * testing purposes.
   */
  endSession: () => void;
}

export interface DynatraceStoragePlugin {
  /**
   * Method to be called when need to start session monitoring in
   * dynatrace by passing cashNumber received from Profile api
   */
  setDynatraceUser(cashNumber: string): Promise<boolean>;

  /**
   * Method to be called when need to close session monitoring in
   * dynatrace
   */
  clearUser(): Promise<boolean>;
}

export class DynatracePlugin implements DynatraceStoragePlugin {
  constructor(private readonly dynatracePlugin: DynatraceApiPlugin) {}

  setDynatraceUser(cashNumber: string): Promise<boolean> {
    return new Promise((resolve) => {
      if (cashNumber && isDynatrace(this.dynatracePlugin)) {
        this.dynatracePlugin.identifyUser(cashNumber);
        resolve(true);
      }
    });
  }

  clearUser(): Promise<boolean> {
    return new Promise((resolve) => {
      if (isDynatrace(window.dtrum)) {
        this.dynatracePlugin.endSession();
        resolve(true);
      }
    });
  }
}

export class DynatraceNoopPlugin implements DynatraceStoragePlugin {
  setDynatraceUser(): Promise<boolean> {
    return Promise.resolve(false);
  }

  clearUser(): Promise<boolean> {
    return Promise.resolve(false);
  }
}

/**
 * This function will help in checking the type of value passed
 * and if all condition met then only we will call dynatrace Plugin
 */
export const isDynatrace = (value: DynatraceApiPlugin) => {
  return (
    value &&
    typeof value.identifyUser === 'function' &&
    typeof value.endSession === 'function'
  );
};

import { useState, useEffect } from 'react';
import { useDynatraceConfig } from 'apps/acp/packages/acp-dynatrace';

export const useStartDynatraceSession = (userIdentifier: string): boolean => {
  const dynatraceStoragePlugin = useDynatraceConfig();
  const [sessionSaved, setSessionSaved] = useState<boolean>(false);

  useEffect(() => {
    let disposed = false;
    (async () => {
      try {
        const isSessionSaved = await dynatraceStoragePlugin.setDynatraceUser(
          userIdentifier
        );
        if (!disposed) {
          setSessionSaved(isSessionSaved);
        }
      } catch (e) {
        if (!disposed) {
          setSessionSaved(false);
        }
      }
    })();

    // We can tell react to call this function when our hook isn't active
    return () => {
      disposed = true;
    };
  }, [setSessionSaved, dynatraceStoragePlugin, userIdentifier]);

  return sessionSaved;
};
